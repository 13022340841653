<template>
  <div>
    <component :is="layout">
      <slot />
    </component>
    <section v-if="backtotop">
      <a id="back-to-top" rel="nofollow" title="Back to top" class="show" @click="moveUp"
        ><img src="@/assets/images/back_to_top_arrow.svg" alt="back to top" />
        <!-- <div>SUS</div> -->
      </a>
    </section>
    <section>
      <a id="whatsapp" rel="nofollow" href="https://wa.me/40748226454" target="_blank" :class="backtotop ? '' : 'bottom-83'">
        <img src="@/assets/images/whatsapp.svg" alt="WhatsApp" />
      </a>
    </section>
  </div>
</template>

<script>
import AppLayoutDefault from "./AppLayoutDefault";
import { shallowRef } from "vue";

export default {
  name: "AppLayout",
  data: () => ({
    layout: shallowRef(AppLayoutDefault),
    backtotop: false,
  }),
  watch: {
    $route: {
      immediate: true,
      async handler(route) {
        try {
          const component = await import(`@/layouts/${route.meta.layout}.vue`);
          this.layout = component?.default || AppLayoutDefault;
        } catch (e) {
          this.layout = AppLayoutDefault;
        }
      },
    },
  },
  methods: {
    handleScroll() {
      // console.log(window.scrollY);
      // console.log(document.body.offsetHeight);
      if (window.scrollY > 100) {
        this.backtotop = true;
      } else {
        this.backtotop = false;
      }
    },
    moveUp() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  // this will register the event when the component is mounted on the DOM
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  // We then unregister the listener when the component is removed from the DOM
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },

};
</script>

<style scoped>
#back-to-top {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  bottom: 83px;
}

#whatsapp {
  position: fixed;
  right: 15px;
  bottom: 133px;
  z-index: 11;
}

#whatsapp img{
  width: 40px;
  height: 40px;
}

.bottom-83 {
  bottom: 83px !important;
}
</style>
