<template>
  <the-header></the-header>
  <div class="min-height">
    <slot />
  </div>
  <the-footer></the-footer>
  <!-- <the-footer v-if="isDataLoad"></the-footer> -->
</template>

<script>
// @ is an alias to /src
import TheHeader from "@/components/layouts/TheHeader.vue";
import TheFooter from "@/components/layouts/TheFooter.vue";
import { generalMixin } from "@/mixins/generalMixin";

export default {
  name: "DefaultLayout",
  components: {
    TheHeader,
    TheFooter,
  },
  mixins: [generalMixin],
};
</script>

<style>
.min-height {
  min-height: 850px;
}

@media (max-width: 576px) {
  .min-height #listproducts,
  .min-height .margin-top {
    /* height: 230px; */
    margin-top: 60px;
  }
  #listproducts .breadcrumb-list {
    background-color: #f5f4f2;
  }
}
</style>
